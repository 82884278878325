export const TEXT = {
  menu: {
    home: 'Home',
    hrsa_audit_tool: 'HRSA Audit Tool',
    upload: 'Upload',
    data_input: 'Data Input',
    npi_eligibility: 'NPI Eligibility',
    category_classification: 'Category Classification',
    patient_source: 'Patient Source',
    source_management: 'Source Management',
    fee_calculation: 'Fee Calculation',
    field_configuration: 'Field Configuration',
    fee_management: 'Fee Management',
    support: 'Support',
    admin: 'Admin',
    synchronization: 'Synchronization',
    user_roles_management: 'User Roles Management',
    aux_rules_management: 'AUX Rules Management',
    ad_management: 'AD Management',
    users: 'Users',
    covered_entities: 'Covered Entities',
    memberships: 'Memberships',
    streams_and_apps: 'Streams and Apps',
    shared_groups: 'Shared Groups',
    pharmasee: 'Pharmasee',
  },
  screen: {
    home: {
      title: 'Home',
    },
    hrsa: {
      title: 'HRSA Audit Tool',
    },

    upload: {
      title: 'Upload',
    },
    data_input: {
      title: 'Data Input',
    },
    npi_eligibility: {
      title: 'NPI Eligibility for Divergence tracking',
    },
    source_management: {
      title: 'Manage Patient Source categories',
    },
    field_configuration: {
      title: 'Field Configuration',
    },
    fee_management: {
      title: 'Fee Management',
    },
    support: {
      title: 'Support',
    },
    synchronization: {
      title: 'Synchronization',
    },
    user_roles_management: {
      title: 'User Roles Management',
    },
    aux_rules_management: {
      title: 'AUX Rules Management',
    },
    pharmasee: {
      title: 'Pharmasee',
    },
    users: {
      title: 'Manage Users',
    },
    category_classification: {
      title: 'Category Classification',
    },
    covered_entities: {
      title: 'Manage Covered Entities',
    },
    memberships: {
      title: 'Manage Memberships',
    },
    streams_and_apps: {
      title: 'Manage Streams and Applications',
    },
    shared_groups: {
      title: 'Manage Shared Groups',
    },
  },
}

const PATH_HOME = '/'
const PATH_UPLOAD = '/upload'
const PATH_DATA_INPUT = '/category-classification'
const PATH_NPI_ELIGIBILITY = '/npi-eligibility'
const PATH_SOURCE_MANAGEMENT = '/source-management'
const PATH_FIELD_CONFIGURATION = '/field-configuration'
const PATH_FEE_MANAGEMENT = '/fee-management'
const PATH_SANDBOX = '/sandbox'
const PATH_SUPPORT = '/support'
const PATH_ADMIN = '/admin'
const PATH_SYNCHRONIZATION = PATH_ADMIN + '/synchronization'
const PATH_USER_ROLES_MANAGEMENT = PATH_ADMIN + '/user-roles-management'
const PATH_AUX_RULES_MANAGEMENT = PATH_ADMIN + '/aux-rules-management'
export const PATH_AD_MANAGEMENT = PATH_ADMIN + '/ad-management'
const PATH_PHARMASEE = '/pharmasee'
const HRSA_AUDIT = '/hrsa-audit'

export const PATH = {
  home: PATH_HOME,
  hrsa: HRSA_AUDIT,
  upload: {
    upload: PATH_UPLOAD,
    history: PATH_UPLOAD + '/history',
  },
  data_input: {
    npi_eligibility: PATH_NPI_ELIGIBILITY,
  },
  patient_source: {
    source_management: PATH_SOURCE_MANAGEMENT,
  },
  fee_calculation: {
    field_configuration: {
      flags: PATH_FIELD_CONFIGURATION,
      rates: PATH_FIELD_CONFIGURATION + '/rates',
      ranges: PATH_FIELD_CONFIGURATION + '/ranges',
    },
    fee_management: {
      fees: PATH_FEE_MANAGEMENT,
      drafts: PATH_FEE_MANAGEMENT + '/drafts',
      mappings: PATH_FEE_MANAGEMENT + '/mappings',
    },
  },
  sandbox: PATH_SANDBOX,
  support: PATH_SUPPORT,
  admin: {
    synchronization: PATH_SYNCHRONIZATION,
    user_roles_management: {
      permissions: PATH_USER_ROLES_MANAGEMENT,
      resources: PATH_USER_ROLES_MANAGEMENT + '/resources',
    },
    aux_rules_management: {
      validation_rules: PATH_AUX_RULES_MANAGEMENT,
      upload_template: PATH_AUX_RULES_MANAGEMENT + '/upload-template',
      invoices_mapping: PATH_AUX_RULES_MANAGEMENT + '/invoice-mapping',
    },
    category_classifications: PATH_DATA_INPUT + '/category-classifications',
    category_classification: PATH_DATA_INPUT + '/category-classification',
    ad_management: {
      users: PATH_AD_MANAGEMENT + '/users',
      user: PATH_AD_MANAGEMENT + '/user',
      covered_entities: PATH_AD_MANAGEMENT + '/covered-entities',
      covered_entity: PATH_AD_MANAGEMENT + '/covered-entity',
      memberships: PATH_AD_MANAGEMENT + '/memberships',
      membership: PATH_AD_MANAGEMENT + '/membership',
      streams_and_apps: PATH_AD_MANAGEMENT + '/streams-and-apps',
      shared_groups: PATH_AD_MANAGEMENT + '/shared-groups',
    },
  },
  pharmasee: PATH_PHARMASEE,
}

import { AxiosResponse } from 'axios'
import { THrsa, THrsaMeta, THrsas } from '../type/hrsa'
import { customErrorResponse } from '../utility/custom-error-response'
import { request, setCustomOnResponseError, resetCustomOnResponseError, CustomAxiosRequestConfig } from './axios'
import { createDownloadLink } from '../utility/download-link'

export const HRSA = {
  get: async (search_key = '', page_number = 1): Promise<THrsas> => {
    const searchParam = search_key ? `&search_key=${search_key}` : ''
    const {
      data: { results, max_page_size, page_no, total_count, total_pages },
    } = await request({
      url: `/hrsa-audits/audits/?page_number=${page_number}${searchParam}`,
    })

    return {
      results,
      max_page_size,
      page_no,
      total_count,
      total_pages,
    }
  },

  details: async (id: string): Promise<THrsa> => {
    const { data } = await request({
      url: `/hrsa-audits/audit/?audit_id=${id}`,
    })

    return await data.audit
  },

  getMeta: async (): Promise<THrsaMeta> => {
    const { data } = await request({
      url: '/hrsa-audits/meta/',
    })
    return await data
  },

  create: async (hrsa_audit: THrsa) => {
    setCustomOnResponseError(customErrorResponse)
    return request({
      method: 'post',
      url: '/hrsa-audits/audit/',
      data: hrsa_audit,
    }).finally(() => {
      resetCustomOnResponseError()
    })
  },

  upload: async (audit_id: string, category_name: string, document_type: string, files: File[]): Promise<AxiosResponse> => {
    const url = `/hrsa-audits/documents/upload/?audit_id=${encodeURIComponent(audit_id)}&category_name=${encodeURIComponent(
      category_name
    )}&document_type=${encodeURIComponent(document_type)}`
    const formData = new FormData()
    files.forEach((file) => formData.append('file', file))

    return request({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url,
      method: 'post',
      data: formData,
      showToast: false,
    } as CustomAxiosRequestConfig)
  },

  download: async (audit_id: string, category_name: string, document_type: string): Promise<void> => {
    const url = `/hrsa-audits/documents/download/?audit_id=${encodeURIComponent(audit_id)}&category_name=${encodeURIComponent(
      category_name
    )}&document_type=${encodeURIComponent(document_type)}`

    const response = await request({
      responseType: 'arraybuffer',
      url,
      method: 'post',
    })

    const contentDisposition = response.headers['content-disposition']
    let fileName = `${audit_id}`

    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
      if (fileNameMatch && fileNameMatch[1]) {
        fileName = fileNameMatch[1]
      }
    } else {
      const contentType = response.headers['content-disposition']
      const extension = contentType.split('/')[1]
      fileName = `${audit_id}.${extension}`
    }

    createDownloadLink(response.data, fileName)
  },
}
